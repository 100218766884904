.bg-dark {
    background-color: #313c8f !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100% !important;
}

.sidebar {
    min-height: calc(100vh - 88px);
}

.table td {
    vertical-align: middle !important;
}