.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #fff;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #333;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-dark {
    background-color: #313c8f !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100% !important;
}

.sidebar {
    min-height: calc(100vh - 88px);
}

.table td {
    vertical-align: middle !important;
}
